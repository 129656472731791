/* Reset box-sizing */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Body reset */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #3F1E43;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4f2753;
}

/* Hide horizontal scrollbar */
::-webkit-scrollbar-horizontal {
  display: none;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #3F1E43 #f1f1f1;
}
